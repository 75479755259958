import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { isMobile } from "react-device-detect/main"
import "../../styles/globals.css"
import BrowserLayout from "./browser/layout"
import MobileLayout from "./mobile/layout"

const Layout = ({ children, className }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {isMobile ? (
        <MobileLayout
          children={children}
          className={className}
          title={data.site.siteMetadata?.title}
        />
      ) : (
        <BrowserLayout
          children={children}
          className={className}
          title={data.site.siteMetadata?.title}
        />
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
