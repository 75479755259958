import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Logo from "../logo"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      youtube: file(relativePath: { eq: "youtube-logo.svg" }) {
        publicURL
      }
      linkedin: file(relativePath: { eq: "linkedin-logo.svg" }) {
        publicURL
      }
    }
  `)
  return (
    <footer className="row">
      <Logo />
      <div className="column flex-center">
        <div className="row footer-informations-links">
          <a
            href="/mentions-legals/"
            onClick={() =>
              window.gtag("event", "navigation", {
                event_category: "footer",
                event_label: "mentions-legals",
              })
            }
          >
            Mentions légales
          </a>
          -
          <a
            href="/politique-confidentialite/"
            onClick={() =>
              window.gtag("event", "navigation", {
                event_category: "footer",
                event_label: "politique-confidentialite",
              })
            }
          >
            Politique de confidentialité
          </a>
          -
          <a
            href="/plan-du-site/"
            onClick={() =>
              window.gtag("event", "navigation", {
                event_category: "footer",
                event_label: "plan-du-site",
              })
            }
          >
            Plan du site
          </a>
        </div>
        <p>
          <span className="italic">2017 - {new Date().getFullYear()}</span> ©
          Sébastien Outreville
        </p>
      </div>
      <div className="column">
        <div className="row footer-links">
          <a
            href="https://www.youtube.com/channel/UCYOz_2CjNpSFniiaalVBTLQ"
            target="_blank"
            rel="noreferrer"
            onClick={() =>
              window.gtag("event", "navigation", {
                event_category: "footer",
                event_label: "youtube",
              })
            }
          >
            <img
              className="footer-img-links"
              src={data?.youtube?.publicURL}
              alt="logo youtube"
            />
          </a>
          <a
            href="https://www.linkedin.com/in/sebastienoutreville/"
            target="_blank"
            rel="noreferrer"
            onClick={() =>
              window.gtag("event", "navigation", {
                event_category: "footer",
                event_label: "linkedin",
              })
            }
          >
            <img
              className="footer-img-links"
              src={data?.linkedin?.publicURL}
              alt="logo linkedin"
            />
          </a>
        </div>
        <a
          href="tel:0617338495"
          className="phone-number"
          onClick={() =>
            window.gtag("event", "click", {
              event_category: "footer",
              event_label: "call",
            })
          }
        >
          +33 6 17 33 84 95
        </a>
      </div>
    </footer>
  )
}

export default Footer
