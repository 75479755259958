import React from "react"
import Footer from "../footer"
import Header from "../header"

const Layout = ({ children, className, title }) => {
  const _className = `layout-desktop ${className}`
  return (
    <>
      <Header siteTitle={title} />
      <main className={_className}>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
