import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const _renderLink = (linkId, linkTitle) => (
  <Link
    id={linkId}
    to="/"
    className="row flex-center"
    onClick={() =>
      window.gtag("event", "click", {
        event_category: "logo",
        event_label: "/",
      })
    }
  >
    <StaticImage
      className="logo"
      src="../images/aci-logo.svg"
      alt="ACI"
      placeholder="tracedSVG"
    />
    {linkTitle ? <p>{linkTitle}</p> : null}
  </Link>
)

const Logo = ({ linked = true, id, title }) => {
  return linked ? (
    _renderLink(id, title)
  ) : (
    <StaticImage
      className="logo"
      src="../images/aci-logo.svg"
      alt="ACI"
      placeholder="tracedSVG"
    />
  )
}

export default Logo
