import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { BrowserView, MobileView } from "react-device-detect"
import { routes } from "../../constants/routes.contants"
import Logo from "../logo"

const HeaderPhoneContact = () => {
  return (
    <a
      className="main-color"
      href="tel:0617338495"
      onClick={() =>
        window.gtag("event", "click", {
          event_category: "header",
          event_label: "call",
        })
      }
    >
      +33 6 17 33 84 95
    </a>
  )
}

const HeaderLinksContact = () => {
  const data = useStaticQuery(graphql`
    query {
      youtube: file(relativePath: { eq: "youtube-logo.svg" }) {
        publicURL
      }
      linkedin: file(relativePath: { eq: "linkedin-logo.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <div className="row header-links">
      <a
        href="https://www.youtube.com/channel/UCYOz_2CjNpSFniiaalVBTLQ"
        target="_blank"
        rel="noreferrer"
        onClick={() =>
          window.gtag("event", "navigation", {
            event_category: "header",
            event_label: "youtube",
          })
        }
      >
        <img
          className="header-img-links"
          src={data?.youtube?.publicURL}
          alt="youtube"
        />
      </a>
      <a
        href="https://www.linkedin.com/in/sebastienoutreville/"
        target="_blank"
        rel="noreferrer"
        onClick={() =>
          window.gtag("event", "navigation", {
            event_category: "header",
            event_label: "linkedin",
          })
        }
      >
        <img
          className="header-img-links"
          src={data?.linkedin?.publicURL}
          alt="logo youtube"
        />
      </a>
    </div>
  )
}

const HeaderNavigation = () => {
  return (
    <nav>
      {routes
        .filter(route => route.id < 100)
        .map(route => {
          return (
            <div className="flex-center">
              <Link
                key={`button-key-routes-${route.path}`}
                activeClassName="active"
                to={route.path}
                onClick={() =>
                  window.gtag("event", "navigation", {
                    event_category: "menu",
                    event_label: route.screenTitle,
                  })
                }
              >
                {route.screenTitle}
              </Link>
            </div>
          )
        })}
    </nav>
  )
}

const Header = () => {
  return (
    <header>
      <BrowserView className="header-desktop">
        <div className="header-desktop-header">
          <HeaderPhoneContact />
          <Logo />
          <HeaderLinksContact />
        </div>
        <HeaderNavigation />
      </BrowserView>
      <MobileView className="column mobile-header">
        <Logo />
        <HeaderNavigation />
        <div className="header-links">
          <HeaderPhoneContact />
          <HeaderLinksContact />
        </div>
      </MobileView>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
