export const ROUTES_ID = {
  APROPOS: 0,
  CONSEILS: 1,
  NEWS: 2,
  CONTACTS: 3,
  MENTIONS: 100,
  CONFIDENTIALITE: 101,
  PLAN: 101,
}

export const routes = [
  { id: ROUTES_ID.APROPOS, screenTitle: "A propos", path: "/a-propos/" },
  {
    id: ROUTES_ID.CONSEILS,
    screenTitle: "Conseils en investissements",
    path: "/conseils/",
  },
  { id: ROUTES_ID.NEWS, screenTitle: "Actualités", path: "/news/" },
  { id: ROUTES_ID.CONTACTS, screenTitle: "Contacts", path: "/contacts/" },

  {
    id: ROUTES_ID.MENTIONS,
    screenTitle: "Mentions Légales",
    path: "/mentions-legals/",
  },
  {
    id: ROUTES_ID.CONFIDENTIALITE,
    screenTitle: "Politique de confidentialité",
    path: "/politique-confidentialite/",
  },
  {
    id: ROUTES_ID.PLAN,
    screenTitle: "Plan du site",
    path: "/plan-du-site/",
  },
]

export const getRoute = id => routes.find(route => route.id === id)
