import { LeftOutlined, MenuOutlined } from "@ant-design/icons"
import React, { useState } from "react"
import Footer from "../footer"
import Header from "../header"

const Layout = ({ children, className, title }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className={`row mobile-menu ${isOpen ? "menu-open" : "menu-close"}`}>
      <div className="mobile-menu-container">
        <LeftOutlined onClick={() => setIsOpen(false)} />
        <Header siteTitle={title} />
      </div>
      <div className="mobile-content">
        <main className={className}>
          <MenuOutlined
            className={isOpen ? "menu-open" : "menu-close"}
            onClick={() => setIsOpen(true)}
          />
          {children}
        </main>
        <Footer />
      </div>
    </div>
  )
}

export default Layout
